var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9b30c60ee64537d355c7214f1590dbecbf9a19e5"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://885ee0d6288344659932fb7887c7b893@o390735.ingest.sentry.io/4504605242163200',
  tracesSampleRate:
    process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 0.1 : 1.0,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  enabled: Boolean(process.env.NEXT_PUBLIC_SENTRY_ENV),
});
