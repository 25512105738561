import { getStorageKeyPath } from '@/utils/browser.utils';

export const accessibilitySettingLocalStorageKeys = Object.freeze({
  useOpenDyslexicFont: getStorageKeyPath('accessibility_use-opendyslexic-font'),
  fontSize: getStorageKeyPath('accessibility_font-size'),
  useHighContrast: getStorageKeyPath('accessibility_use-high-contrast'),
});

export const navigationHistorySessionStorageKey: Readonly<string> =
  getStorageKeyPath('navigation-history');
