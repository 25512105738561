import { firestore } from 'firebase-admin';
import { logEvent } from 'firebase/analytics';

import { BaseModelData, DocumentSnapshotData } from '@/types/index';

import { analytics } from '@/libs/firebase/firebase-js-sdk';

export function parseSnapshotData<T extends BaseModelData>(
  docSnapshot: firestore.DocumentSnapshot<T>
): DocumentSnapshotData<T> | null {
  const data = docSnapshot.data();
  if (!data) {
    return null;
  }

  const { createdAt, updatedAt, ...rest } = data;

  return {
    uid: docSnapshot.id,
    ...JSON.parse(JSON.stringify(rest)),
    createdAt: createdAt?.toDate?.().toISOString() ?? null,
    updatedAt: updatedAt?.toDate?.().toISOString() ?? null,
  } as DocumentSnapshotData<T>;
}

export function logFirebaseEvent(
  eventName: string,
  eventParams?: Record<string, any>
) {
  const analyticsInstance = analytics();

  if (analyticsInstance) {
    logEvent(analyticsInstance, eventName, eventParams);
  }
}
