import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import * as React from 'react';

import '@/styles/globals.css';

import { NavigationHistory } from '@/types/index';

import { navigationHistorySessionStorageKey } from '@/config/browser-storage.config';

import { AccessibilitySettingsProvider } from '@/hooks/useAccessibilitySettings';

import { logFirebaseEvent } from '@/utils/firebase.utils';

import { defaultOptions } from '@/libs/react-query';

import ErrorBoundary from '@/components/ErrorBoundary';

// enable aXe during development
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

function MyApp({
  Component,
  pageProps,
}: AppProps<{ dedehydratedState: DehydratedState }>) {
  const [queryClient] = React.useState(() => {
    return new QueryClient({
      defaultOptions,
    });
  });

  const router = useRouter();
  // store successful navigations in session storage
  //
  // (useful for knowing user's browsing history on the website in current session
  //   e.g. for back buttons / navigations)
  React.useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      const currentHistoryString = window.sessionStorage.getItem(
        navigationHistorySessionStorageKey
      );
      const currentHistory = currentHistoryString
        ? (JSON.parse(currentHistoryString) as NavigationHistory)
        : null;

      const newHistory: NavigationHistory = {
        history: currentHistory ? [...currentHistory.history, url] : [url],
      };

      window.sessionStorage.setItem(
        navigationHistorySessionStorageKey,
        JSON.stringify(newHistory)
      );
    };

    // store initial route too
    // (Next.js only handles subsequent route changes)
    const initialRoute = router.asPath;
    handleRouteChangeComplete(initialRoute);

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    logFirebaseEvent('screen_view');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route]);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dedehydratedState}>
          <AccessibilitySettingsProvider>
            <Component {...pageProps} />
          </AccessibilitySettingsProvider>
        </Hydrate>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
