import * as Sentry from '@sentry/nextjs';
import * as React from 'react';
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';
import { BiDizzy } from 'react-icons/bi';
import { MdRefresh } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

export function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  const { useHighContrast } = useAccessibilitySettings();

  return (
    <section
      className={twMerge(
        'p-6 w-full flex justify-center text-center',
        useHighContrast ? 'bg-contrast-yellow text-black' : 'bg-transparent'
      )}
    >
      <div className="flex flex-col items-center max-w-[700px]">
        <BiDizzy className="text-gray-900 mb-6 h-10 w-10" aria-hidden="true" />

        <h2
          className={twMerge(
            'text-3xl mb-3',
            useHighContrast ? 'text-black' : 'text-gray-900'
          )}
        >
          An unexpected error has occurred.
        </h2>

        <p
          className={twMerge(
            'text-lg',
            useHighContrast ? 'text-black' : 'text-gray-600'
          )}
        >
          We apologize for the inconvenience. Our team has been notified and is
          working to resolve the issue as quickly as possible. Please try
          refreshing the page or contact our support team for assistance.
        </p>

        <button
          type="button"
          onClick={resetErrorBoundary}
          className={twMerge(
            'btn btn-lg w-[120px] mt-10',
            useHighContrast ? 'btn-contrast-yellow' : 'btn-primary'
          )}
        >
          Retry
          <MdRefresh className="h-5 w-5 ml-2" aria-hidden="true" />
        </button>
      </div>
    </section>
  );
}

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const handleReset = () => {
    window.location.reload();
  };

  const errorHandler = (error: Error, info: { componentStack: string }) => {
    Sentry.captureException(error, {
      extra: {
        componentStack: info.componentStack,
      },
    });
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={handleReset}
      onError={errorHandler}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
