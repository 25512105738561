import * as React from 'react';

import { accessibilitySettingLocalStorageKeys } from '@/config/browser-storage.config';

import { logFirebaseEvent } from '@/utils/firebase.utils';

type AccessibilitySettingsContextValue = {
  useOpenDyslexicFont: boolean;
  fontSize: 'default' | 'large' | 'extra-large';
  useHighContrast: boolean;
  handleUseDyslexicFontChange: (
    value: AccessibilitySettingsContextValue['useOpenDyslexicFont']
  ) => void;
  handleFontSizeChange: (
    value: AccessibilitySettingsContextValue['fontSize']
  ) => void;
  handleUseHighContrastChange: (
    value: AccessibilitySettingsContextValue['useHighContrast']
  ) => void;
};

const AccessibilitySettingsContext = React.createContext<
  AccessibilitySettingsContextValue | undefined
>(undefined);

type AccessibilitySettingsProviderProps = {
  children?: React.ReactNode;
};

function AccessibilitySettingsProvider({
  children,
}: AccessibilitySettingsProviderProps) {
  const [useOpenDyslexicFont, setUseOpenDyslexicFont] =
    React.useState<AccessibilitySettingsContextValue['useOpenDyslexicFont']>(
      false
    );

  const [fontSize, setFontSize] =
    React.useState<AccessibilitySettingsContextValue['fontSize']>('default');

  const [useHighContrast, setUseHighContrast] =
    React.useState<AccessibilitySettingsContextValue['useHighContrast']>(false);

  // get settings from localstorage (if there are any)
  React.useEffect(() => {
    setUseOpenDyslexicFont(
      window.localStorage.getItem(
        accessibilitySettingLocalStorageKeys.useOpenDyslexicFont
      ) === 'true'
    );
    setFontSize(
      (window.localStorage.getItem(
        accessibilitySettingLocalStorageKeys.fontSize
      ) as AccessibilitySettingsContextValue['fontSize']) ?? 'default'
    );
    setUseHighContrast(
      window.localStorage.getItem(
        accessibilitySettingLocalStorageKeys.useHighContrast
      ) === 'true'
    );
  }, []);

  // activate opendyslexic font on website
  React.useEffect(() => {
    const body = document.querySelector('body');

    if (useOpenDyslexicFont) {
      body?.classList.add('opendyslexic');
    } else {
      body?.classList.remove('opendyslexic');
    }
  }, [useOpenDyslexicFont]);

  // reflect font size change on website
  React.useEffect(() => {
    const html = document.querySelector('html');

    switch (fontSize) {
      case 'default':
        html?.classList.remove('large-font');
        html?.classList.remove('extra-large-font');
        break;
      case 'large':
        html?.classList.remove('extra-large-font');
        html?.classList.add('large-font');
        break;
      case 'extra-large':
        html?.classList.remove('large-font');
        html?.classList.add('extra-large-font');
        break;
      default:
        break;
    }
  }, [fontSize]);

  // activate high contrast mode on website
  React.useEffect(() => {
    const body = document.querySelector('body');

    if (useHighContrast) {
      body?.classList.add('high-contrast-mode');
    } else {
      body?.classList.remove('high-contrast-mode');
    }
  }, [useHighContrast]);

  const handleUseDyslexicFontChange = (
    value: AccessibilitySettingsContextValue['useOpenDyslexicFont']
  ) => {
    setUseOpenDyslexicFont(value);

    if (value) {
      logFirebaseEvent('enable_dyslexic_font');
    }

    // sync settings to localstorage for persistency
    window.localStorage.setItem(
      accessibilitySettingLocalStorageKeys.useOpenDyslexicFont,
      value.toString()
    );
  };

  const handleFontSizeChange = (
    value: AccessibilitySettingsContextValue['fontSize']
  ) => {
    setFontSize(value);

    logFirebaseEvent('font_size_change', { value });

    // sync settings to localstorage for persistency
    window.localStorage.setItem(
      accessibilitySettingLocalStorageKeys.fontSize,
      value
    );
  };

  const handleUseHighContrastChange = (
    value: AccessibilitySettingsContextValue['useHighContrast']
  ) => {
    setUseHighContrast(value);

    if (value) {
      logFirebaseEvent('enable_high_contrast');
    }

    // sync settings to localstorage for persistency
    window.localStorage.setItem(
      accessibilitySettingLocalStorageKeys.useHighContrast,
      value.toString()
    );
  };

  return (
    <AccessibilitySettingsContext.Provider
      value={{
        useOpenDyslexicFont,
        fontSize,
        useHighContrast,
        handleUseDyslexicFontChange,
        handleFontSizeChange,
        handleUseHighContrastChange,
      }}
    >
      {children}
    </AccessibilitySettingsContext.Provider>
  );
}

const useAccessibilitySettings = () => {
  const context = React.useContext(AccessibilitySettingsContext);

  if (context === undefined) {
    throw new Error(
      'useAccessibilitySettings must be used within an AccessibilitySettingsProvider'
    );
  }

  return context;
};

export { AccessibilitySettingsProvider, useAccessibilitySettings };
